<template>
  <div>
    <b-card-code title="Update delivery note">
      <b-card-text>
        <span>Please enter delivery_note details</span>
      </b-card-text>

      <!-- form -->
      <validation-observer ref="simpleRules">

        <validation-observer ref="simpleRules">
          <div>
            <div>
              <b-form
                ref="form"
                :style="{ height: trHeight }"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >

                <b-row>
                  <b-col
                    md="6"
                  >
                    <b-form-group
                      label="delivery note number"
                      label-for="delivery note number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="delivery note number"
                        rules="required"
                      >
                        <b-form-input
                          v-model="delivery_note.number"
                          :state="errors.length > 0 ? false : null"
                          placeholder="delivery note number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="Customer"
                      label-for="Customer"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Customer"
                        rules="required"
                      >
                        <label class="d-inline d-lg-none">Customer</label>
                        <v-select
                          v-model="delivery_note.customer"
                          placeholder="Select Item"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="customers"
                          label="code"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Row Loop -->
                <b-row
                  v-for="(item, index) in items"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- produit -->
                  <b-col md="4">
                    <b-form-group
                      label="Product"
                      label-for="Product"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Produit"
                        rules="required"
                      >
                        <label class="d-inline d-lg-none">Item</label>
                        <v-select
                          v-model="item.produit"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="products"
                          label="item_code"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Quantity -->
                  <b-col md="4">
                    <b-form-group
                      label="NBR . CTN"
                      label-for="quantity"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        id="quantity"
                        v-model="item.quantity"
                        type="number"
                        placeholder="Quantity"
                        :on-change="alert1(item.quantity*item.produit.nbr_export,item.produit.stock_received_tunisia,item,index)"
                      />
                      <b-form-input
                        v-else
                        id="quantity"
                        type="number"
                        placeholder="Number of carton"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                      label="NBR . PCS / CTN"
                      label-for="NBR . PCS / CTN"
                    >
                      <b-form-input
                        v-model="item.produit.nbr_export"
                        placeholder="Number of piece by carton"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Total . NBR . PCS"
                      label-for="NBR . PCS"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.nbr_export * item.quantity"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="sales price / CTN"
                      label-for="PA"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.pv_ctn_export"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Total . Cost price"
                      label-for="PA"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="(item.produit.pv_ctn_export * item.quantity).toFixed(2)"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="2"
                    md="6"
                    class="mt-2 mb-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0"
                      @click="removeItem1(index,item)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-row

                  v-for="(item, index) in items2"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <!-- produit -->
                  <b-col md="4">
                    <b-form-group
                      label="Product"
                      label-for="Product"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Produit"
                        rules="required"
                      >
                        <label class="d-inline d-lg-none">Item</label>
                        <v-select
                          v-model="item.produit"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="products"
                          label="item_code"
                          :clearable="false"
                          class="mb-2 item-selector-title"
                          placeholder="Select Item"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Quantity -->
                  <b-col md="4">
                    <b-form-group
                      label="NBR . CTN"
                      label-for="quantity"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        id="quantity"
                        v-model="item.quantity"
                        type="number"
                        placeholder="Quantity"
                        :on-change="alert2(item.quantity*item.produit.nbr_export,item.produit.stock_received_tunisia,item,index)"
                      />

                      <b-form-input
                        v-else
                        id="quantity"
                        type="number"
                        placeholder="Number of carton"
                        :on-change="alert2(item.quantity*item.produit.nbr_export,item.produit.stock_received_tunisia,item,index)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="4">
                    <b-form-group
                      label="NBR . PCS / CTN"
                      label-for="NBR . PCS / CTN"
                    >
                      <b-form-input
                        v-model="item.produit.nbr_export"
                        placeholder="Number of piece by carton"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Total . NBR . PCS"
                      label-for="NBR . PCS"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.nbr_export * item.quantity"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Cost price / CTN"
                      label-for="PA"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="item.produit.pa_ctn_import"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Total . Cost price"
                      label-for="PA"
                    >
                      <b-form-input
                        v-if="item.produit.item_code"
                        v-model="(item.produit.pa_ctn_import * item.quantity).toFixed(2)"
                        placeholder="Total number of piece"
                      />
                      <b-form-input
                        v-else
                        placeholder="Total Number of piece"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="6"
                    class="mt-0 mb-1"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
              </b-form>
            </div>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mt-5"
              @click="repeateAgain1"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>New product</span>
            </b-button>
            <b-row v-if="somme">
              <b-col
                md="12"
                class="mt-2 mr-2"
              >
                <b-form-group
                  label="Total cost price"
                  label-for="Total cost price"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Total cost price"
                    rules="required"
                  >
                    <b-form-input
                      v-model="somme.toFixed(2)"
                      disabled
                      :state="errors.length > 0 ? false : null"
                      placeholder="Total cost price"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row />
            <b-row>
              <b-col md="12">
                <span class="title">
                  Date:
                </span>
                <flat-pickr
                  v-model="delivery_note.date"
                  class="form-control invoice-edit-input"
                />
              </b-col>
            </b-row>
            <b-row>

              <b-col md="12">
                <b-form-group
                  label="Comment"
                  label-for="Comment"
                >
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="delivery_note.comment"
                    placeholder="Comment"
                    rows="8"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-3"
                  @click.prevent="updateDeliveryNote"
                >
                  Update
                </b-button>
                <b-button
                  variant="outline-secondary"
                  type="reset"
                  class="mt-3"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>

          </div>
        </validation-observer>
      </validation-observer>
    </b-card-code></div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'

/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'
import { onMounted } from '@vue/composition-api'
import useCustomer from '@/views/customers/useCustomer'
import useProduct from '@/views/products/useProduct'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
    flatPickr,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          id: 1,
          type: '',
          produit: 0,
          quantity: 1,
        },
      ],
      items2: [

      ],
      nextTodoId: 1,
      supplier: { company_name: '' },
      delivery_note: {
        comment: '',
        status: 0,
        number: '',
      },
      required,
    }
  },
  computed: {
    somme() {
      const s = this.items.reduce((accumulator, item) => accumulator + (item.produit.pv_ctn_export * item.quantity), 0)
      if (this.items2.length !== 0) {
        const n = this.items2.reduce((accumulator, item) => accumulator + (item.produit.pv_ctn_export * item.quantity), 0)

        return s + n
      }
      return s
    },

  },
  created() {
    this.items.shift()
    this.items2.shift()
    this.getDeliveryNote()
  },
  mounted() {
    this.initTrHeight()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    alert1(quantity, inTunisia, item, index) {
      if (quantity > inTunisia) { this.withTitle1(inTunisia, item, index) }
    },
    alert2(quantity, inTunisia, item, index) {
      if (quantity > inTunisia) { this.withTitle2(inTunisia, item, index) }
    },
    withTitle1(quantity, item, index) {
      this.$swal({
        title: 'Stock Alerte',
        icon: 'warning',
        text: `Quantity is greater than stock, you have ${quantity} PCS`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
    },
    withTitle2(quantity, item, index) {
      this.$swal({
        title: 'Stock Alerte',
        icon: 'warning',
        text: `Quantity is greater than stock, you have ${quantity} PCS`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.removeItem(index)
        }
      })
    },
    async removeItem1(index, item) {
      this.$swal({
        showCancelButton: true,
        title: 'Are you sure !!',
        icon: 'warning',
        text: 'Are sure to remove this product from this delivery note!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          console.log(item)
          this.items.splice(index, 1)
          this.somme -= (item.produit.pv_ctn_export * item.quantity)

          await this.$http.post(

            '/delete-products-delivery-note/',
            { delivery_note: this.delivery_note.id, item, somme: this.somme },
          ).then(async () => {
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          })
        }
      })
    },
    async getDeliveryNote() {
      await this.$http.get(`/delivery-notes/detail/${this.$route.params.id}/`).then(response => {
        this.delivery_note = response.data
      })
      if (this.delivery_note != null) {
        this.$http.post(
          '/delivery-note-products/detail/',
          { delivery_note: this.delivery_note.id, products: this.delivery_note.products },
        ).then(res => {
          const result = res.data
          for (let i = 0; i < result.length; i += 1) {
            this.delivery_note.products[i].pa_ctn_import = result[i].pa_ctn
            this.repeateAgain(this.delivery_note.products[i], result[i].quantity)
          }
        })
      }
    },
    repeateAgain1() {
      this.items2.push({
        id: this.nextTodoId += 1,
        type: '',
        produit: '',
        quantity: 1,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    repeateAgain(p, q) {
      this.items.push({
        id: this.nextTodoId += 1,
        type: '',
        produit: p,
        quantity: q,
        old_quantity: q,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items2.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    removeItem2(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    async updateDeliveryNote() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          await axiosIns.post('/delivery-notes/update-delivery-notes-product/', {
            number: this.delivery_note.number,
            comment: this.delivery_note.comment,
            status: 0,
            date: this.delivery_note.date,
            total_pa: this.somme.toFixed(2),
            products: this.items2,
            products_old: this.items,
            delivery_note: this.delivery_note.id,
          }).then(() => {
            this.$router.push({ name: 'delivery-notes' })
            this.showToast('success', 'top-center', 'delivery note successfully updated')
          })
            .catch(error => {
              this.showToast('danger', 'top-center', error.toString())
            })
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const { products, getProducts } = useProduct()
    const { customers, getCustomers } = useCustomer()

    onMounted(() => {
      getProducts()
      getCustomers()
    })

    return {
      products,
      getProducts,
      customers,
      getCustomers,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
