import { ref } from '@vue/composition-api'
import axiosIns from '@/libs/axios'

export default function useCustomers() {
  const customers = ref([])

  const getCustomers = async () => {
    const { data } = await axiosIns.get('/customers/', { params: { is_deleted: false } })
    customers.value = data
  }

  return {
    customers,
    getCustomers,
  }
}
